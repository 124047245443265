/*Imagenes*/
.full-screen {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
}

@media (min-width: 650px ) {
    .full-screen {
        background-position: center center;
    }
}

/*Imagenes estáticas*/
.wh-container-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-position: 50% 50%;
}

/*Rellenar todo el div*/
.wh-full-img{
    height: 100%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-position: 50% 50%;
}

/*Color de fondo*/
.black{
    background-color: #000;
}

option:hover {
    background: black ;
    color: red;
  }